body {
  font-family: "ProximaNova-Regular", 'TeXGyreHerosRegular', "Helvetica", "Arial", sans-serif;
  /*  "Helvetica Neue", sans-serif; */
  margin: 0;
  background-color: white;
  overflow: hidden;
}

.content-tag {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 138px);
}

.basic-container {
  padding: 30px;
}

.version-info {
  font-size: 8pt;
  float: right;
}

.container-box {
  margin-left: 30px;
  margin-right: 30px;
  padding-left: 15px;
  padding-right: 15px;
}

.CMI-button {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 1px;
  margin-right: 13px;
}

.primaryButton {
  color: white;
  background-color: teal;
  border: transparent;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 1px;
  margin-right: 13px;
}

.primaryButton1 {
  color: white;
  background-color: teal;
  border: transparent;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 20px;
  /* margin-bottom: 20px; */
  margin-left: 1px;
  margin-right: 13px;
}


.primaryButton:hover {
  background-color: black;
  color: white;
}

.secondaryButton {
  color: white;
  background-color: darkblue;
  border: transparent;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 1px;
  margin-right: 13px;
}

.secondaryButton:hover {
  background-color: black;
  color: white;
}

.cancelButton {
  color: white;
  background-color: gray;
  border: transparent;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 1px;
  margin-right: 13px;
}

.cancelButton:hover {
  background-color: black;
  color: white;
}

.disabledButton {
  color: grey;
  background-color: lightgrey;
  border: transparent;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: not-allowed!important;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 1px;
  margin-right: 13px;
}

.tableAction {
  color: teal;
  cursor: pointer;
  font-size: 14px;
  padding: 10px;
  width: fit-content;
  display: inline-block;
  margin-bottom: 0px;
  /* background-color: white !important; */
  background-color: transparent;
  border: transparent !important;
}

.tableActionDisabled {
  color: grey !important;
  cursor: not-allowed;
  font-size: 14px;
  padding: 10px;
  width: fit-content;
  display: inline-block;
  background-color: white !important;
  border: transparent !important;
  cursor: not-allowed !important;
}

@media screen and (min-width:920px) {
  .pageTitle {
    border-bottom: 2px solid lightgrey;
    margin-top: 15px;
    padding-left: 0px;
    margin-bottom: 10px;
  
}

.pageTitle1 {
  border-bottom: 2px solid lightgrey;
  margin-top: 15px;
  padding-left: 0px;
  margin-bottom: 10px;
  margin-right:70px;
  margin-left:-15px
}


  .titleText {
    font-size: 30px;
    font-weight: bold;
    font-family: "ProximaNova-Regular", 'TeXGyreHerosRegular', "Helvetica", "Arial", sans-serif;
    /* "Helvetica Neue", sans-serif; */
    line-height: 32px;
    border-bottom: 2px solid teal;
    width: fit-content;
    margin-bottom: -2px;
    display: inline-block;
    color: black;
  }
  .subtitleText {
    font-size: 16px;
    font-weight: bold;
    font-family: "ProximaNova-Regular", 'TeXGyreHerosRegular', "Helvetica", "Arial", sans-serif;
    /* "Helvetica Neue", sans-serif; */
    line-height: 32px;
    width: fit-content;
    display: inline-block;
    color: black;
  }
  .no-border {
    border: none !important
  }
}

@media screen and (max-width:920px) {
  .pageTitle {
    border-bottom: 2px solid lightgrey;
    margin-top: 15px;
    padding-left: 0px;
    margin-bottom: 10px;
  }
  .titleText {
    font-size: 20px;
    font-weight: bold;
    font-family: "ProximaNova-Regular", 'TeXGyreHerosRegular', "Helvetica", "Arial", sans-serif;
    /* "Helvetica Neue", sans-serif; */
    line-height: 22px;
    border-bottom: 2px solid teal;
    width: fit-content;
    margin-bottom: -2px;
    color: black;
  }
  .subtitleText {
    font-size: 16px;
    font-weight: bold;
    font-family: "ProximaNova-Regular", 'TeXGyreHerosRegular', "Helvetica", "Arial", sans-serif;
    /* "Helvetica Neue", sans-serif; */
    line-height: 32px;
    border-bottom: 2px solid teal;
    width: fit-content;
    margin-bottom: -2px;
    display: inline-block;
    color: black;
  }
}

.mat-checkbox-layout {
  cursor: inherit;
  align-items: baseline;
  vertical-align: -webkit-baseline-middle !important;
  display: inline-table;
  white-space: nowrap;
  background-color: white !important;
  margin-bottom: 5px;
}

tr.mat-footer-row, tr.mat-row {
  height: auto !important;
}

td.mat-cell {
  border-bottom: 1px solid #999999;
  border-right: 1px solid #ddd;
  text-align: left;
  height: 35px;
  font-size: 13px;
  white-space: nowrap;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

th.mat-header-cell {
  background-color: black !important;
  color: white !important;
  text-align: left;
  border-right: 1px solid #ddd;
  white-space: nowrap;
}

.mat-header-cell {
  font-size: 14px !important;
}

th.mat-header-cell .mat-checkbox-layout {
  background-color: black !important;
}

th.mat-header-cell:last-of-type {
  padding-right: 0px !important;
}

.mat-sort-header-icon-disabled {
  display: none !important;
}

.mat-checkbox-inner-container {
  margin-right: 8px !important;
  background: white;
}

/* .mat-sort-header-icon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -70px -3591px;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-left: 15px;
  cursor: pointer;
  transform: scale(0.5);
} */

.mat-sort-header-arrow {
  margin: 3px 0 0 6px !important;
  color: white;
  opacity: 1 !important;
  transform: translateY(0%) !important;
}

/* .mat-sort-header-arrow {
  height: 12px;
  width: 12px;
  min-width: 12px;
  position: relative;
  display: none !important;
  opacity: 0;
}

.mat-sort-header-button {
  border: none !important
} */

.mat-expansion-panel-body {
  padding: 0 !important;
}

button, a {
  cursor: pointer !important;
}

button:focus {
  outline: none;
}

.actionButton {
  color: teal !important;
  background-color: white;
  border: 1px solid teal;
  border-radius: 5px;
  padding: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
}

.actionButtonDisabled {
  pointer-events: none;
  color: grey !important;
  background-color: white;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 10px;
  cursor: not-allowed;
  font-size: 14px;
  text-decoration: none;
}

.actionButton:hover {
  color: white !important;
  background-color: teal;
}

/* .btn {
  color: white;
  font-size: 13px;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer !important;
} */

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  padding-top: 5px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:hover {
  background-color: black;
  /* box-shadow: 5px 5px 6px #999999 !important; */
  color: white;
}

.btn-add {
  background-color: teal;
}

.btn-cancel {
  background-color: #87a7bb;
  float: right;
}

.btn-reset {
  background-color: #87a7bb;
}

.btn-primary {
  background-color: teal !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(49, 77, 104, 0.7);
  box-shadow: inset 0 0 6px rgba(49, 77, 104, 0.7);
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  border-radius: 1px;
  -webkit-box-shadow: inset 0 0 6px rgba(49, 77, 104, 0.9);
  box-shadow: inset 0 0 6px rgba(49, 77, 104, 0.9);
}

::-moz-scrollbar {
  width: 10px;
  height: 10px;
}

::-moz-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(49, 77, 104, 0.7);
  box-shadow: inset 0 0 6px rgba(49, 77, 104, 0.7);
  border-radius: 1px;
}

::-moz-scrollbar-thumb {
  border-radius: 1px;
  -webkit-box-shadow: inset 0 0 6px rgba(49, 77, 104, 0.9);
  box-shadow: inset 0 0 6px rgba(49, 77, 104, 0.9);
}

.tableHeaderLabel {
  display: flex;
  padding: 8px 10px;
  justify-content: center;
  /*   border-bottom: 1px solid #ddd; */
}

.tableColumnFilter {
  background-color: white;
  /* line-height: 15px; */
  border-radius: 4px;
  width: 100%;
  /* margin-left: 10%; */
  /* margin-right: 10%; */
  /* margin-top: 5px; */
  /* margin-bottom: 5px; */
  color: black;
  padding-left: 5px;
  border: 2px hidden black;
}

.inputbox {
  display: inline-block;
  width: 80%;
  height: 30px !important;
  padding-left: 10px !important;
  text-transform: uppercase;
}

.mat-row:nth-child(even) {
  background-color: #eeeeee !important;
}

.mat-row:nth-child(odd) {
  background-color: white !important;
}

td .mat-cell, td .mat-footer-cell, th .mat-header-cell {
  background-color: black !important;
}

th.mat-header-cell:first-of-type {
  padding-left: 10px !important;
  display: table-cell;
}

td.mat-column-star {
  width: 20px;
  padding-right: 8px;
}

th.mat-column-position, td.mat-column-position {
  padding-left: 8px;
}

.mat-checkbox-inner-container {
  margin-right: 8px !important;
  background: white !important;
}

.mat-expansion-panel-header:not([aria-disabled=true]) {
  cursor: pointer;
  padding-left: 10px;
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  /*  border: 1px solid rgba(0, 0, 0, .38); */
  border: none;
  box-shadow: none;
}

.danger-icon {
  background: url('assets/img/CMI-WebIcon-Sprite.png') no-repeat -130px -2811px;
  width: 20px;
  height: 18px;
  display: inline-block;
  margin-left: 5px;
  vertical-align: text-bottom;
}

.success-icon {
  display: inline-block;
  background: url('assets/img/CMI-WebIcon-Sprite.png') no-repeat -191px -4192px;
  width: 17px;
  height: 14px;
  vertical-align: text-bottom;
}

.warning-icon {
  display: inline-block;
  background: url('assets/img/CMI-WebIcon-Sprite.png') no-repeat -160px -2690px;
  width: 20px;
  height: 20px;
  vertical-align: text-bottom;
}

.info-icon {
  background: url('assets/img/CMI-CS-sprite-8Dec2016.png') no-repeat -169px -619px;
  width: 23px;
  height: 22px;
  display: inline-block;
  margin-left: 5px;
  vertical-align: text-bottom;
}

.calenderIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -100px -3110px;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.initiateIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -100px -3380px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
}

.initiateIconDisabled {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -40px -3380px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
}

.initiateApprovalIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -100px -200px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
}

.actionButton:hover>.initiateApprovalIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -70px -200px;
  ;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
}

.primaryButton>.initiateApprovalIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -70px -200px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
}

.initiateApprovalIconDisabled {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -40px -200px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
}

.inputbox-mat-date {
  display: inline-block;
  width: 8em !important;
  height: 30px !important;
  padding-left: 10px !important;
  font-size: 12px !important;
  padding-right: 10px !important;
  color: black !important;
  background-color: white !important;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background: transparent !important;
}

.mat-ripple-element {
  background-color: transparent !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  color: black;
  border-color: rgba(0, 0, 0, .38);
}

.mat-datepicker-toggle, .mat-datepicker-toggle-active {
  color: #007c7c;
}

.mat-calendar-body-selected {
  background: #007c7c;
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: none;
}

.mat-calendar-body-cell-content {
  border-radius: 0px !important;
  width: 70% !important;
  height: 70% !important;
}

.mat-calendar-body-cell-content:hover {
  background: #ddd !important;
}

.mat-datepicker-content .mat-calendar {
  width: 15em !important;
  height: 18em !important;
}

.mat-alert {
  box-shadow: 0 0 8px 0 #ccc;
  margin: 5px;
  margin-right: 15px;
  margin-left: 15px;
}

.danger-x {
  padding-left: 15px;
  float: right;
  cursor: pointer;
  color: #D02323;
}

.success-x {
  padding-left: 15px;
  float: right;
  cursor: pointer;
  color: #08A74D;
}

.warning-x {
  padding-left: 15px;
  float: right;
  cursor: pointer;
  color: #FbBA29;
}

.info-x {
  padding-left: 10px;
  float: right;
  cursor: pointer;
  color: #0091e6;
}

.danger-msg {
  border: 2px solid #D02323;
  background-color: #FDEDED;
  padding: 10px;
  font-size: 14px;
}

.success-msg, .SUCCESS {
  border: 2px solid #08A74D;
  background-color: #E8FCBC;
  padding: 10px;
  font-size: 14px;
}

.warning-msg {
  border: 2px solid #F6BA29;
  background-color: #FFF0C5;
  padding: 10px;
  font-size: 14px;
}

.info-msg {
  border: 2px solid #0091e6;
  background-color: #ccecff;
  padding: 10px;
  font-size: 14px;
}

.formLabel {
  width: 60% !important;
  color: black !important;
  margin-bottom: 2px !important;
  display: block !important;
  text-align: left !important;
  font-size: 14px !important;
}

.modalLabel {
  width: 40% !important;
  color: black !important;
  margin-bottom: 2px !important;
  display: inline-block !important;
  text-align: right !important;
  padding-right: 20px;
}

.modalButtonPanel {
  padding-left: 18em;
  border-top: 1px solid lightgray;
  padding-top: 10px;
}

.form-control {
  width: 80% !important;
  color: black;
  border: 1px solid rgb(211, 211, 211);
  transition: none;
  height: 30px;
  padding: 5px;
  line-height: 1 !important;
  font-size: 13px;
  padding-left: 5px;
  display: inline-block;
  box-shadow: none;
}

.form-control:focus {
  border: 1px solid teal !important;
  transition: none;
  height: 30px;
  outline: none !important;
  box-shadow: 0 2px 4px -1px #ddd;
}

.form-control:hover, .form-control:active {
  border: 1px solid teal !important;
  transition: none;
  /*height: 30px; */
  outline: none !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
}

.form-control:disabled, .form-control[disabled] {
  border: 1px solid gray;
  box-shadow: none !important;
  background-color: #e2e2e2;
  transition: none;
  height: 30px;
}

.gly-space {
  margin-left: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.form-group {
  display: inline-block;
  margin-bottom: 10px;
}

.cdk-overlay-container {
  z-index: 99999 !important;
}

.mat-option-text {
  font-size: 14px !important;
}

.mat-option {
  border-top: 1px solid #999;
  height: 35px !important;
}

.mat-option:hover {
  background-color: teal !important;
  color: white !important;
}

.mat-select-value {
  padding-top: 3px;
  padding-left: 8px;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  color: black;
  background: white;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple):hover {
  color: white;
  background: teal;
}

.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  color: black;
}

.mat-select:hover {
  cursor: pointer;
}

.mat-select-panel {
  max-height: 19em !important;
}

.mat-select-content, .mat-select-panel-done-animating {
  max-height: 40vh;
}

.mat-select-panel.mat-select-panel:not([class*=mat-elevation-z]) {
  margin-top: 1.7em;
  border: 1px solid #007c7c;
}

.mat-select-panel .mat-optgroup-label, .mat-select-panel .mat-option:hover {
  background-color: teal;
  color: white;
}

.mat-option.mat-active {
  background-color: teal;
  color: white;
}

.mat-select:focus:not(.mat-select-disabled).mat-primary .mat-select-arrow, .mat-select-underline, .mat-select-trigger {
  color: white;
}

.mat-select-disabled {
  background: #e2e2e2;
  cursor: not-allowed !important;
}

.mat-select-disabled:hover {
  background: #e2e2e2;
  cursor: not-allowed !important;
}

.mat-select-disabled .mat-select-value:hover {
  cursor: not-allowed;
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, .87);
}

/*Pager*/

.prev {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -100px -230px;
  width: 20px;
  height: 20px;
}

.next {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -100px -170px;
  width: 20px;
  height: 20px;
}

.pagination>.active>a, .pagination>.active>span, .pagination>.active>a:hover, .pagination>.active>span:hover, .pagination>.active>a:focus, .pagination>.active>span:focus {
  padding-top: 6px !important;
}

.pagination>li:first-child>span {
  content: "" !important;
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -100px -170px;
  width: 20px;
  height: 20px;
}

.pagination>li:first-child>a {
  content: "" !important;
  background: url('assets/img/leftarrow.png') no-repeat;
  width: 20px;
  padding-left: 33px;
  padding-bottom: 8px;
  padding-top: 4px !important;
  padding-right: 33px;
  background-position-y: center;
}

.pagination>li:first-child>a::after {
  content: 'Prev';
  color: teal;
  position: relative;
  top: 2.5px;
}

.pagination>li:first-child>a>span {
  display: none;
}

.dp {
  display: none !important;
}

.doter {
  color: teal;
  font-size: 14px;
}

.pg-prev {
  content: ">>>" !important;
  background: none;
  width: 20px;
  padding-left: 10px;
  padding-bottom: 6px;
  padding-top: 0px;
  padding-right: 26px;
  font-size: 16px;
}

.pg-prev>a>span {
  display: none;
}

.pagination>li:last-child>a {
  content: "" !important;
  background: url('assets/img/rightarrow.png') no-repeat;
  width: 20px;
  padding-bottom: 8px;
  padding-top: 4px !important;
  padding-right: 55px;
  background-position-x: right;
  background-position-y: center;
}

.pagination>li:last-child>a::before {
  content: 'Next';
  color: teal;
  position: relative;
  top: 2.5px;
}

.pagination>li:last-child>a>span {
  display: none;
}

.recdisplay {
  margin-top: 12px;
  padding-left: 10px;
}

.tableContainerBig {
  max-height: calc(100vh - 316px);
  overflow: auto;
}

.tableContainerSmall {
  max-height: 35vh;
  overflow: auto;
}

.actionContainer {
  width: 100%;
  padding: 0px;
  padding-left: 5px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.tableContainer {
  width: 100%;
  padding: 0px;
  border-bottom: 2px solid #ddd;
  /* overflow: auto; */
}

.pagination {
  margin-bottom: 0px;
  width: fit-content;
}

.pageLink:hover {
  background-color: black !important;
  color: white !important;
}

.search-text-field {
  width: 200px;
  border: 1px solid rgb(211, 211, 211);
  border-radius: 5px;
  padding: 5px;
  font-size: 13px;
  color: black;
  width: 70%;
}

.jumpPagelbl {
  width: 10em!important;
  height: 30px!important;
  margin-left: 5px!important;
  margin-top: 10px!important;
}

.jumpPagetxt {
  width: 5em!important;
  height: 30px;
  margin: 5px;
}

.activePageLink {
  background-color: teal !important;
  color: white !important;
}

.pageSizeDropdown {
  width: 70px !important;
}

.jumpToInput {
  display: inline-block !important;
  margin-left: 5px;
  width: 75px !important;
}

.deleteIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -100px -440px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-right: 2px;
  margin-bottom: -4px;
}

.deleteIconWhite {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -70px -440px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-right: 2px;
  margin-bottom: -4px;
}

.deleteIconDisabled {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -40px -440px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-right: 2px;
  margin-bottom: -4px;
}

.addIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -100px -320px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
}

.addIconDisabled {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -40px -320px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
}

.copyIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -100px -1520px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
}

.copyIconDisabled {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat no-repeat -40px -1520px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
}

.backIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -100px -140px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
}

.nextIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -100px -200px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
}

.backIconButton {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -70px -140px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
}

.cancelIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -100px -20px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
}

.cancelIconDisabled {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -40px -20px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
}

.resetIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -70px -860px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
  margin-right: 2px;
}

.exportExcelIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -100px -3920px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
}

.emailIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -100px -1040px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
}

.searchIcon {
  background: url('assets/img/CMI-WebIcon-Sprite.png') no-repeat -100px -2120px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
  /* margin-right: 2px; */
}

.pickIcon {
  background: url('assets/img/CMI-WebIcon-Sprite.png') no-repeat -101px -711px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.7);
  margin-bottom: -4px;
  margin-right: 2px;
}

.pickIcon:hover, .pickIcon:active {
  background: url('assets/img/CMI-WebIcon-Sprite.png') no-repeat -11px -711px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.7);
  margin-bottom: -4px;
  margin-right: 2px;
}

.saveIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -100px -1400px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
}

.saveIconDisabled {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -40px -1400px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
}

.editIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -100px -920px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
}

.editIconWhite {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -70px -920px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-right: 2px;
  margin-bottom: -4px;
}

.editIconDisabled {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -40px -920px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
}

.history {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -100px -3200px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.95);
  margin-bottom: -4px;
}

.historyDisabled {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -40px -3200px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.95);
  margin-bottom: -4px;
}

.whereUsed {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -100px -3500px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.95);
  margin-bottom: -4px;
}

.whereUsedDisabled {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -40px -3500px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.95);
  margin-bottom: -4px;
}

.searchInputs {
  display: inline-block;
  padding-left: 10px;
  padding-right: 20px;
  font-size: 12px;
  font-weight: 400;
}

.doneIcon {
  background: url('assets/img/CMI-WebIcon-Sprite.png') no-repeat -100px -2840px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
}

.doneIconDisabled {
  background: url('assets/img/CMI-WebIcon-Sprite.png') no-repeat -40px -2840px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
}

.uploadIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -100px -1730px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.7);
  margin-bottom: -5px;
  margin-right: 2px;
}

.uploadIcon-Button {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -70px -1700px;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-bottom: -5px;
  margin-right: 2px;
}

.uploadIcon-ButtonDisabled {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -40px -1700px;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-bottom: -5px;
  margin-right: 2px;
}

.cir_uploadIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -100px -1700px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -5px;
  margin-right: 2px;
}

.cir_uploadIconDisabled {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -40px -1700px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.7);
  margin-bottom: -5px;
  margin-right: 2px;
}

.uploadIconDisabled {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -40px -1700px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.7);
  margin-bottom: -5px;
  margin-right: 2px;
}

.subscreenIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -101px -711px;
  width: 18px;
  height: 18px;
  display: inline-block;
  margin-bottom: -5px;
  margin-left: 5px;
  margin-right: 2px;
  cursor: pointer;
}

.actionButton:hover>.downloadIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -71px -1610px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.7);
  margin-bottom: -5px;
  margin-right: 2px;
}

.actionButton:hover>.uploadIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -71px -1730px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.7);
  margin-bottom: -5px;
  margin-right: 2px;
}

.actionButton:hover>.cir_uploadIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -71px -1700px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.7);
  margin-bottom: -5px;
  margin-right: 2px;
}

.cir_notesIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -100px -3680px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(1);
  margin-bottom: -5px;
  margin-right: 2px;
}

.cir_tagIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -100px -920px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(1);
  margin-bottom: -5px;
  margin-right: 2px;
}

.cir_downloadIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat  -100px -1580px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -5px;
  margin-right: 2px;
}

.mandatoryAlert {
  color: red;
  display: block;
  font-size: 12px;
  padding-left: 10px;
  float: right;
}

.validationAlert {
  color: red;
  padding-left: 5px;
  display: block;
  font-size: 12px;
}

.itemPerPage {
  width: 10em !important;
  height: 30px!important;
  margin: 0px 5px 10px 5px !important;
}

.modal-content {
  width: 75%;
  margin-left: 20%;
  margin-top: 10%;
}

.modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0.6 !important;
}

.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: .5!important;
}

ngb-modal-window {
  z-index: 9999 !important;
}

.bg-warning {
  background-color: rgb(0, 0, 0) !important;
}

.modal-header {
  background-color: rgb(0, 0, 0);
  color: white;
  font-size: 18px;
  padding: 10px;
}

#overlays {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  cursor: pointer;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.search-buttonGroup {
  padding: 6px 10px !important;
  margin-top: 10px !important;
}

.search-control {
  padding: 6px 10px !important;
}

.left {
  float: left;
}

.pagination>li>a {
  font-size: 12px;
  padding-top: 6px !important;
}

.mat-autocomplete-panel.mat-autocomplete-visible {
  visibility: visible;
  max-height: 250px !important;
}

.mat-autocomplete-panel {
  min-width: max-content !important;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: teal !important;
}

.buttonGroup {
  padding: 5px;
}

.action-col {
  width: 84px;
}

input.ng-touched.ng-invalid {
  border: 1px solid red !important;
}

input.ng-dirty.ng-invalid {
  border: 1px solid red !important;
}

textarea.ng-touched.ng-invalid {
  border: 1px solid red !important;
}

textarea.ng-dirty.ng-invalid {
  border: 1px solid red !important;
}

.highLight_row .mat-cell, .mat-footer-cell {
  font-weight: bold;
  color: teal !important
}

.highLightLocked_row .mat-cell, .mat-footer-cell {
  color: darkgrey !important;
}

.displayNone {
  display: none;
}

.dataLockFlag {
  background: url('assets/img/CMI-WebIcon-Sprite.png') no-repeat -12px -4677px;
  width: 15px;
  height: 20px;
  display: inline-block;
}

.highLight_cell {
  color: teal !important;
  font-weight: bold;
}

.highLight_cell .mat-select-value {
  color: #007c7c !important;
}

highLight_cell .mat-select:focus:not(.mat-select-disabled).mat-primary .mat-select-arrow, .mat-select-underline, .mat-select-trigger {
  color: #007c7c !important;
}

.primaryButtonPagination {
  color: white;
  background-color: teal;
  border: transparent;
  border-radius: 5px;
  padding: 4px 10px;
  cursor: pointer;
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 7px;
  margin-left: 0px;
  margin-right: 0px;
}

.disabledButtonPagination {
  color: white;
  background-color: gray;
  border: transparent;
  border-radius: 5px;
  padding: 4px 10px;
  cursor: not-allowed;
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 7px;
  margin-left: 0px;
  margin-right: 0px;
}

.prow {
  margin: 0 !important;
  display: inline-flex;
  padding-left: 35%;
  padding-top: 5px;
}

.flt-lft {
  float: left !important;
}

.reduce-margin {
  margin-bottom: 5px !important;
}

.p-selected {
  padding-right: 10px;
  padding-left: 10px;
  display: none;
  /*temp*/
}

.filterBox {
  display: none;
}

.successStatus {
  color: green;
}

.errorStatus {
  color: red;
}

/* .mat-icon-button {
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
} */

/*applying light color for placeholders*/

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.33) !important;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.33) !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(0, 0, 0, 0.33) !important;
}

/*end of applying light color for placeholders*/

/*permanent background & border even after scroll*/

.mat-row:nth-child(even) .mat-cell {
  background-color: rgb(242, 242, 242) !important;
  border-right: 1px solid lightgray !important;
  border-bottom: 1px solid lightgray !important;
}

.mat-row:nth-child(even) .mat-cell .mat-checkbox-layout {
  background-color: #eeeeee !important;
}

.mat-row:nth-child(odd) .mat-cell {
  background-color: white !important;
  border-right: 1px solid lightgray !important;
  border-bottom: 1px solid lightgray !important;
}

.mat-table thead, [mat-header-row], mat-header-row, .mat-table thead th, .mat-table thead th.mat-header-cell {
  color: white !important;
  background: black;
}

/*end of permanent background & border even after scroll*/

/*mat tool tip*/

.mat-tooltip {
  margin-top: -8px !important;
  white-space: normal;
}

.my-tooltip {
  white-space: pre-line;
}

/*end of tooltip*/

/** txt area **/

.txtarea {
  display: inline-flex;
  height: 60px !important;
  width: 55% !important;
}

.txtarea:hover {
  height: 60px !important;
}

.txtarea-lbl {
  display: inline-flex;
  justify-content: flex-end;
  vertical-align: top;
}

/**end of text aread**/

:root {
  --blue: teal !important;
  /*overiding bootstrap primary color*/
}

.m-hov:hover .mat-cell, .mat-footer-cell {
  color: white !important;
  background-color: teal !important;
}

/* SVG image in externalUserLogin */

.createAccountSvgImg{
  background: url('assets/img/Icons-for-FluidWatch-02.svg') no-repeat 0 0;
  height: 70px;
  width: 150px;
}
.logInToAccountSvgImg{
  background: url('assets/img/Icons-for-FluidWatch-03.svg') no-repeat 0 0;
  height: 70px;
  width: 150px;
}
.purchaseSampleSVGImg{
  background: url('assets/img/Icons-for-FluidWatch-05.svg') no-repeat 0 0;
  height: 70px;
  width: 150px;
}

.purchaseSampleSVGImg2{
  background: url('assets/image/actionsImages/purchase_sample.jpg') no-repeat 0 0;
  height: 70px;
  width: 150px;
}

.addEquip-mat-checkbox-layout {
  cursor: inherit;
  align-items: baseline;
  vertical-align: -webkit-baseline-middle !important;
  display: inline-table;
  white-space: nowrap;
  background-color: white !important;
  margin-bottom: 8px;
}

.addEquip-mat-checkbox-layout:checked{
  background-color: teal;
}

.graphRankInput{
  width: 60px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-left: 3px;
}

.deleteIconGraph {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -100px -440px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-left: 62px;
  margin-bottom: -4px;
}
.graphThumbnail{
width: 150px;
height: 185px;
border: 1px solid lightgray;

}

.purchaseSample2{
  background: url('assets/image/actionsImages/purchase_sample.jpg') no-repeat 0 0;
  height: 70px;
  width: 140px;
 margin: 20px;
 padding-bottom: 80px;
 margin-top: 40px;
}

.registerSample{
  background: url('assets/image/actionsImages/register_samples.jpg') no-repeat 0 0;
  height: 70px;
  width: 170px;
  margin: 20px;
  padding-bottom: 80px;
  margin-top: 40px;
}

.sampleDashboard{
  background: url('assets/image/actionsImages/sample_dashboard.jpg') no-repeat 0 0;
  height: 70px;
  width: 170px;
  margin: 20px;
  padding-bottom: 80px;
  margin-top: 40px;
}

.manageFleet{
  background: url('assets/image/actionsImages/manage_fleet.jpg') no-repeat 0 0;
  height: 70px;
  width: 180px;
  margin: 20px;
  padding-bottom: 80px;
  margin-top: 40px;
  
}

.viewReport{
  background: url('assets/image/actionsImages/my_reports.jpg') no-repeat 0 0;
  height: 70px;
  width: 170px;
  margin: 20px;
  padding-bottom: 80px;
  margin-top: 40px;
}

.customerAccounts{
  background: url('assets/image/actionsImages/customer-accounts.png') no-repeat 0 0;
  height: 70px;
  width: 170px;
  margin: 20px;
  padding-bottom: 80px;
  margin-top: 40px;
}

.adminDashboard{
  background: url('assets/image/actionsImages/admin_dashboard.jpg') no-repeat 0 0;
  height: 70px; 
  width: 170px;
  margin: 20px;
  padding-bottom: 80px;
  margin-top: 40px;
}

.populationManagement{
  background: url('assets/image/actionsImages/population_management.jpg') no-repeat 0 0;
  height: 70px;
  width: 120px;
  padding-bottom: 100px;
  margin-top: 20px;
}

.analyzAndPublishReport{
  background: url('assets/image/actionsImages/my_reports.jpg') no-repeat 0 0;
  height: 70px;
  width: 140px;
  margin: 20px;
}

.reviewSamples{
  background: url('assets/image/actionsImages/review_samples.jpg') no-repeat 0 0;
  height: 70px;
  width: 170px;
  margin: 20px;
  padding-bottom: 80px;
  margin-top: 40px;
}

.lineGraph{
  background: url('assets/image/actionsImages/line_graph.jpg') no-repeat 0 0;
  height: 70px;
  width: 170px;
  margin: 20px;
  padding-bottom: 80px;
  margin-top: 40px;

}

.scatterPlot{
  background: url('assets/image/actionsImages/scatter.jpg') no-repeat 0 0;
  height: 70px;
  width: 170px;
  margin: 20px;
  padding-bottom: 80px;
  margin-top: 40px;
}

.myReport{
  background: url('assets/image/actionsImages/my_reports.jpg') no-repeat 0 0;
  height: 70px;
  width: 170px;
  padding-bottom: 80px;
  margin-top: 40px;
  margin: 20px;
}

/* =====================================firefox close icon================================================= */

.search-box,.close-icon,.search-wrapper {
	position: relative;
	/* padding: 10px; */
}
.search-wrapper {
  width: 100%;
  display: inline-block;
	/* margin: auto;
	margin-top: 50px; */
}
.search-box {
	width: 100%;
	border: 1px solid teal;
  outline: 0;
  border-radius: 4px;
}
.search-box:focus {
	/* box-shadow: 0 0 15px 5px #b0e0ee; */
	border: 2px solid #bebede;
}
.close-icon {
	border:1px solid transparent;
	background-color: transparent;
	display: inline-block;
	vertical-align: middle;
  outline: 0;
  cursor: pointer;
  right: -20px;
  top: 10px;
  position: absolute;
}
.close-icon:after {
	content: "X";
	display: block;
	width: 15px;
	height: 15px;
	position: absolute;
	background-color: white;
	z-index:1;
	right: 22px;
	top: 0;
	bottom: 0;
	margin: auto;
	/* padding: 2px; */
	border-radius: 50%;
	text-align: center;
	color: teal;
	font-weight: normal;
	font-size: 12px;
	/* box-shadow: 0 0 2px #E50F0F; */
	cursor: pointer;
}
.search-box:not(:valid) ~ .close-icon {
	display: none;
}

.oil{
  background: url('assets/image/actionsImages/OIL.JPG') no-repeat 0 0;
  height: 70px;
  width: 70px;
  margin: 20px;
  padding-bottom: 67px;
  margin-top: 15px;
}

.coolant{
  background: url('assets/image/actionsImages/COOLANT.jpg') no-repeat 0 0;
  height: 70px;
  width: 70px;
  margin: 20px;
  padding-bottom: 67px;
  margin-top: 15px;
}
.diesel{
  background: url('assets/image/actionsImages/DIESEL.jpg') no-repeat 0 0;
  height: 70px;
  width: 70px;
  margin: 20px;
  padding-bottom: 67px;
  margin-top: 15px;
}
.def{
  background: url('assets/image/actionsImages/DEF.jpg') no-repeat 0 0;
  height: 70px;
  width: 70px;
  margin: 20px;
  padding-bottom: 67px;
  margin-top: 15px;
}
.ng{
  background: url('assets/image/actionsImages/NG.jpg') no-repeat 0 0;
  height: 70px;
  width: 70px;
  margin: 20px;
  padding-bottom: 67px;
  margin-top: 15px;
}


.rightArrowIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -100px -200px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
}


.viewIcon {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -100px -80px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-bottom: -4px;
}

.viewIconWhite {
  background: url('assets/img/CMI-WebIcon-Sprite-New.png') no-repeat -70px -80px;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: scale(0.8);
  margin-right: 2px;
  margin-bottom: -4px;
}

.customDropdown .mat-select-placeholder{
    color: #ffffff !important;

}

.oil_small{
  background: url('assets/image/actionsImages/OIL_SMALL.JPG') no-repeat 0 0!important;
  height: 32px;
  width: 34px;  
  margin: 20px;
  cursor: pointer;
}

.coolant_small{
  background: url('assets/image/actionsImages/COOLANT_SMALL.jpg') no-repeat 0 0!important;
  height: 32px;
  width: 34px;
  margin: 20px;
}
.diesel_small{
  background: url('assets/image/actionsImages/DIESEL_SMALL.jpg') no-repeat 0 0!important;
  height: 32px;
  width: 34px;
  margin: 20px;
}
.def_small{
  background: url('assets/image/actionsImages/DEF_SMALL.jpg') no-repeat 0 0!important;
  height: 32px;
  width: 34px;
  margin: 20px;
}
.ng_small{
  background: url('assets/image/actionsImages/NG_SMALL.jpg') no-repeat 0 0!important;
  height: 32px;
  width: 34px;
  margin: 20px;
}
.greenBackground{
  background-color: green!important;
}

.arrow_forward {
  content: "" !important;
  background: url('assets/img/rightarrow.png') no-repeat;
  width: 20px;
  padding-left: 33px;
  padding-bottom: 15px;
  padding-top: 4px !important;
  padding-right: 4px;
  background-position-y: center;
  border: none;
}

.arrow_back {
  content: "" !important;
  background: url('assets/img/leftarrow.png') no-repeat;
  width: 20px;
  padding-left: 33px;
  padding-bottom: 15px;
  padding-top: 4px !important;
  padding-right: 4px;
  background-position-y: center;
  border: none;
}
/* =====================================firefox close icon================================================= */